import { Method } from "~/enums";
import { useUserStore } from "./userStore";

// export interface boardDataForm{
//   title: string,
//   writer: string,
//   category: string,
//   detailCategory: string,
//   content: string,
//   attach: any[],
//   thumbnails: any[],
//   top: 1 | null,
//   isOpen: boolean,
//   isPosting: number,
//   password: string | null
// }

export interface qnaDataForm{
  title: string,
  writer: string,
  category: string | null,
  detailCategory: string,
  content: string,
  mainDomain: string,
  subDomain: string,
  attach: any[],
  password: string | null
}

export const useBoardStore = defineStore("boardStore", {
  state: () => ({
    boardData: useLocalStorage('boardData', {
      title: '',
      writer: '',
      category: null,
      detailCategory: '',
      content: '',
      attach: [],
      thumbnails: [],
      top: null,
      isOpen: true,
      password: null
    } as any),
    qnaData: useLocalStorage('qnaData', {
      title: '',
      writer: '',
      category: null,
      detailCategory: '',
      content: '',
      mainDomain: '',
      subDomain: '',
      attach: [],
      password: null
    } as qnaDataForm),
    qnaReplyData: {
      title: '',
      writer: '',
      category: null,
      detailCategory: '',
      content: '',
      mainDomain: '',
      subDomain: '',
      attach: [],
      password: null
    },
    boardInfos: useLocalStorage('boardInfos', [] as any[]),
    boardInfo : useLocalStorage('boardInfo', {} as any),
    boardList: useLocalStorage('boardList', [] as any[]),
    boardCode: useLocalStorage('boardCode', '' as string),
    boardName: useLocalStorage('boardName', '' as string),
    boardSeq: useLocalStorage('boardSeq', 0 as number),
    qnaModifyPassword: '' as string,
    boardDescription: useLocalStorage('boardDescription', '' as string)
  }),
  getters: {},
  actions: {
    reset(){
      this.boardData = {
        title: '',
        writer: '',
        category: '',
        detailCategory: '',
        content: '',
        attach: [],
        thumbnails: [],
        top: null,
        isOpen: true,
        password: null
      };

      this.qnaData = {
        title: '',
        writer: '',
        category: '',
        detailCategory: '',
        content: '',
        mainDomain: '',
        subDomain: '',
        attach: [],
        password: null
      }
    },

    setBoardData(data: any){this.boardData = data},
    setModifyPassword(pass: string){
      this.qnaModifyPassword = pass;
    },
    async setQnaReplyData(data){
      this.qnaReplyData = await this.getDetailQna(data.qnaSeq);
      console.log(this.qnaReplyData);
    },
    
    

    async getBoardInfos(boardSettingSeq: number | undefined = undefined, boardString?: string | undefined){
      if(boardString === 'subscribe'){
        this.boardCode = 'subscribe';
        this.boardInfo = {
          "boardSettingSeq": null,
          "boardName": "구독자관리",
          "boardCode": "subscribe",
          "isPrivatePolicy": "N",
          "isCategory": "N",
          "isDomain": "N",
          "boardCategory": null,
          "mainDomain": null,
          "subDomain": null,
          "isTop": "N",
          "topCount": 0,
          "isPassword": "N",
          "isThumbnail": "N",
          "isPostingPeriod": "N"
        }

        return;
      }

      const res = await useFetch('/api/v1/boards', {method: Method.GET, query: {boardSettingSeq: boardSettingSeq}}) as any;


      this.boardInfo = res.data.value[0];
      this.boardCode = res.data.value[0].boardCode;
      this.boardName = res.data.value[0].boardName;
      this.boardSeq = res.data.value[0].boardSettingSeq;

      this.setBoardDescription();
      
      if(this.boardCode === 'qna'){
        return this.getQna(1, 10);
      } else {
        return this.getBoardList(this.boardCode, 1, 10);
      }
      
   
    },
    setBoardDescription(){
      switch(this.boardInfo.boardCode){
        case 'notice'                : this.boardDescription = '한국녹색기후기술원의 주요 소식을 실시간으로 전해드립니다.'; break;
        case 'qna'                   : this.boardDescription = '기술원의 각 분야별 전문가들이 답변해 드립니다.'; break;
        case 'newsletter'            : this.boardDescription = '시시각각 새롭게 업데이트되는 한국녹색기후기술원 소식을 전해드립니다.'; break;
        case 'result_certification'  : this.boardDescription = '한국녹색기후기술원의 주요 실적 정보를 제공해드립니다.'; break;
        case 'result_diagnosis'      : this.boardDescription = '한국녹색기후기술원의 주요 실적 정보를 제공해드립니다.'; break;
        case 'result_rnd'            : this.boardDescription = '한국녹색기후기술원의 주요 실적 정보를 제공해드립니다.'; break;
        default: break;
      }
    },
    async getBoardList(
      boardCode   : string,
      pageNo      : number,
      pageSize    : number,
      isPosting   : number | undefined = undefined,
      category    : string | undefined = undefined,
      mainDomain  : string | undefined = undefined,
      subDomain   : string | undefined = undefined,
      startDate   : string | undefined = undefined,
      endDate     : string | undefined = undefined,
      searchKind  : 'total' | 'title' | 'content' | undefined = undefined,
      searchText  : string | undefined = undefined
    ){
      
      if(!boardCode) {alert('게시판 코드는 필수 입니다.'); return};
      if(!pageNo) pageNo = 1;
      if(!pageSize) pageSize = 1000;
      if(!searchKind) searchText = undefined;

      let isLogin = 0;
      if(useUserStore().accessToken) isLogin = 1;

        const res = await useFetch(`/api/v1/boards/${boardCode}`, {method: Method.GET, query: {
            category    : category,
            isPosting   : isPosting, 
            mainDomain  : mainDomain,
            isLogin     : isLogin,
            subDomain   : subDomain, 
            pageNo      : pageNo, 
            pageSize    : pageSize,
            startDate   : startDate,
            endDate     : endDate,
            searchKind  : searchKind,
            searchText  : searchText
        }}) as any;

        
        if(res.data.value){
          this.boardList = res.data.value;

          this.boardList.content = this.boardList.content.sort((a, b) => {
            if(a.boardSeq > b.boardSeq) return -1;
            if(a.boardSeq < b.boardSeq) return 1;
            return 0;
          });
  
          return res.data.value;
  
        } else {
          return res.error.value;
        }


  
    },
    async getDetailBoardInfo(boardCode: string, dataSeq: number){
      const res = await useFetch(`/api/v1/boards/${boardCode}/${dataSeq}`, {method: Method.GET});

      return res.data.value;
    },
    async savePopup(
      category: string,
      content: string,
      isAttach: 1 | 0,
      isThumbnail: 1 | 0,
      title: string,
      top : 1 | null,
      userSeq: number,
      files: File[] | undefined = undefined,
      thumbnails: File[] | undefined = undefined,
      isPosting: 1 | 0,
      postPeriodKind: string,
      postStartDate: string,
      postEndDate: string,
      popupWidth: number | undefined = undefined,
      popupHeight: number | undefined = undefined,
      subContent: number | undefined = undefined,
      link: string | undefined = undefined,
    ){
      const boardCode = this.boardCode;
      const boardSettingSeq = this.boardSeq;
      const formData = new FormData();

      let params = {
        boardCode: boardCode,
        boardSettingSeq: boardSettingSeq,
        category: category,
        content: content,
        isAttach: isAttach,
        isThumbnail: isThumbnail,
        title: title,
        top: top,
        userSeq: userSeq,
        isPosting: isPosting,
        postPeriodKind: postPeriodKind,
        postStartDate: postStartDate,
        postEndDate: postEndDate,
        popupWidth : popupWidth,
        popupHeight: popupHeight,
        subContent: subContent,
        link: link,
      }

      console.log(params);

      formData.append('contents', JSON.stringify(params));
      if(files){
        files.forEach(file => {
          formData.append('files', file);
        })
      }
      if(thumbnails) {
        thumbnails?.forEach(thumbnail => {
          formData.append('thumbnails', thumbnail);
        })
      };

      try{
        const res = await useCustomFetch(`/api/v1/boards/${boardCode}`, {method: Method.POST, body: formData});
        return res.data.value;

      } catch(e){
        throw e;
      }
    },

    async saveBoard(
      category: string,
      content: string,
      isAttach: 1 | 0,
      isThumbnail: 1 | 0,
      title: string,
      top : 1 | null,
      userSeq: number,
      files: File[] | undefined = undefined,
      thumbnails: File[] | undefined = undefined,
      isPosting: 1 | 0,
      postPeriodKind: string,
      postStartDate: string,
      postEndDate: string,
      popupWidth: number | undefined = undefined,
      popupHeight: number | undefined = undefined,
      subContent: number | undefined = undefined,
      isLinkButton: boolean | undefined = undefined,
      link: string | undefined = undefined,
    ){
      const boardCode = this.boardCode;
      const boardSettingSeq = this.boardSeq;
      const formData = new FormData();
      const linkButton = isLinkButton;

      let params = {
        boardCode: boardCode,
        boardSettingSeq: boardSettingSeq,
        category: category,
        content: content,
        isAttach: isAttach,
        isThumbnail: isThumbnail,
        title: title,
        top: top,
        userSeq: userSeq,
        isPosting: isPosting,
        postPeriodKind: postPeriodKind,
        postStartDate: postStartDate,
        postEndDate: postEndDate,
        popupWidth : popupWidth,
        popupHeight: popupHeight,
        subContent: subContent,
        isLinkButton: linkButton,
        link: link,
      }

      console.log(params);

      formData.append('contents', JSON.stringify(params));
      if(files){
        files.forEach(file => {
          formData.append('files', file);
        })
      }
      if(thumbnails) {
        thumbnails?.forEach(thumbnail => {
          formData.append('thumbnails', thumbnail);
        })
      };

      try{
        const res = await useCustomFetch(`/api/v1/boards/${boardCode}`, {method: Method.POST, body: formData});
        return res.data.value;

      } catch(e){
        throw e;
      }
    },
    async modifyBoard(
      boardId: number,
      category: string,
      content: string,
      isAttach: 1 | 0,
      isThumbnail: 1 | 0,
      title: string,
      top : 1 | null,
      userSeq: number,
      files: File[] | undefined = undefined,
      thumbnails: File[] | undefined = undefined,
      isPosting: 1 | 0,
      postPeriodKind: string,
      postStartDate: string,
      postEndDate: string,
      popupWidth: number | undefined = undefined,
      popupHeight: number | undefined = undefined,
      subContent: string | undefined = undefined,
      isLinkButton: boolean | undefined = undefined,
      link: string | undefined = undefined,
      
    ){
      const boardCode = this.boardCode;
      const boardSettingSeq = this.boardSeq;
      const formData = new FormData();
      const linkButton = isLinkButton;

      let params = {
        boardCode: boardCode,
        boardSettingSeq: boardSettingSeq,
        category: category,
        content: content,
        isAttach: isAttach,
        isThumbnail: isThumbnail,
        title: title,
        top: top,
        userSeq: userSeq,
        isPosting: isPosting,
        postPeriodKind: postPeriodKind,
        postStartDate: postStartDate,
        postEndDate: postEndDate,
        popupWidth: popupWidth,
        popupHeight: popupHeight,
        subContent: subContent,
        isLinkButton: linkButton,
        link: link
      }

      formData.append('contents', JSON.stringify(params));
      if(files){
        files.forEach(file => {
          formData.append('files', file);
        })
      }
      if(thumbnails) {
        thumbnails?.forEach(thumbnail => {
          formData.append('thumbnails', thumbnail);
        })
      };

      console.log(params);
      try{
        const res = await useCustomFetch(`/api/v1/boards/${boardCode}/${boardId}`, {method: Method.PUT, body: formData});
        return res.data.value;

      } catch(e){
        throw e;
      }
    },
    setModifyToBoard(data: any){
      if(data === null || data === undefined){
        this.boardData = null;
        return;
      }

      this.boardData = data;

      // this.boardData.title = data.title ? data.title : '';
      // this.boardData.writer = data.writer ? data.writer : '';
      // this.boardData.category = data.category ? data.category : '';
      // this.boardData.detailCategory = data.detailCategory ? data.detailCategory : '';
      // this.boardData.content = data.content ? data.content : '';
      this.boardData.attach = data.isAttach === 1 ? data.attachments : [];
      this.boardData.thumbnails = data.isThumbnail === 1 ? data.thumbnails : [];
      // this.boardData.top = data.top ? data.top: null;
      // this.boardData.isOpen = data.isOpen ? data.isOpen : true;
      // this.boardData.password = data.password ? data.password : null;
      // this.boardData.isPosting = data.isPosting ? data.isPosting : null;
      // this.boardData.isLinkButton = data.isLinkButton;
      // this.boardData.link = data.link;
      // this.boardData.popupHeight = data.popupHeight;
      // this.boardData.popupWidth = data.popupWidth;
      // this.boardData.subContent = data.subContent;
      // this.boardData.postEndDate = data.postEndDate;
      
    },
    async getQna(
      pageNo          : number,
      pageSize        : number,
      mainDomain      : string | undefined = undefined,
      subDomain       : string | undefined = undefined,
      startDate       : string | undefined = undefined,
      endDate         : string | undefined = undefined,
      searchKind      : 'total' | 'title' | 'content' | undefined = undefined,
      searchText      : string | undefined = undefined,
    ){
      if(!pageNo) pageNo = 1;
      if(!pageSize) pageSize = 1000;
      if(!searchKind) searchText = undefined;

      let isLogin = 0;
      if(useUserStore().accessToken) isLogin = 1;

      const res = await useFetch(`/api/v1/boards/qna`, {method: Method.GET, query: {
        pageNo      : pageNo,
        pageSize    : pageSize,
        isLogin     : isLogin,
        mainDomain  : mainDomain,
        subDomain   : subDomain,
        startDate   : startDate,
        endDate     : endDate,
        searchKind  : searchKind,
        searchText  : searchText
      }}) as any;

      this.boardList = res.data.value;

      this.boardList.content = this.boardList.content.sort((a, b) => {
        if(a.boardSeq > b.boardSeq) return -1;
        if(a.boardSeq < b.boardSeq) return 1;
        return 0;
      });

      return res.data.value;
    },
    async createQna(
        content         : string,
        isAttach        : 0 | 1,
        mainDomain      : string,
        subDomain       : string,
        password        : string,
        title           : string,
        writer          : string,
        files           : File[]
    ){
      let boardCode = this.boardCode;
      let boardSettingSeq = this.boardSeq;
      

      const form = new FormData();

      const bodyParam = {
        boardCode       : boardCode,
        boardSettingSeq : boardSettingSeq,
        content         : content,
        isAttach        : isAttach,
        mainDomain      : mainDomain,
        password        : password,
        subDomain       : subDomain,
        title           : title,
        writer          : writer
      }


      form.append('contents', JSON.stringify(bodyParam));

      if(files.length > 0){
        for(let i = 0; i < files.length; i++){
          form.append('files', files[i]);
        }
      }
  
      try{
        const res = await useCustomFetch('/api/v1/boards/qna', {method:Method.POST, body: form});
        console.log(res);
        return res?.data.value;

      } catch(e){
        throw e;
      }      
    },
    async modifyQna(     
      qnaSeq          : number,
      content         : string,
      isAttach        : 0 | 1,
      mainDomain      : string,
      subDomain       : string,
      password        : string,
      title           : string,
      writer          : string,
      files           : File[]
  ){
      let boardCode = this.boardCode;
      let boardSettingSeq = this.boardSeq;
      
      const form = new FormData();

      const bodyParam = {
        boardCode       : boardCode,
        boardSettingSeq : boardSettingSeq,
        content         : content,
        isAttach        : isAttach,
        mainDomain      : mainDomain,
        password        : password,
        subDomain       : subDomain,
        title           : title,
        writer          : writer
      }


      form.append('contents', JSON.stringify(bodyParam));

      if(files.length > 0){
        for(let i = 0; i < files.length; i++){
          form.append('files', files[i]);
        }
      }

      try{
        const res = await useCustomFetch(`/api/v1/boards/qna/${qnaSeq}`, {method:Method.PUT, body: form});
        console.log(res);
        return res?.data.value;

      } catch(e){
        throw e;
      }    
    },
    async getDetailQna(qnaSeq: number, password: string | undefined){
      console.log(password);
      const isAdmin = useUserStore().accessToken ? 1 : 0;

      let confirmPass = !password ? 'null' : password

      const res = await useFetch(`/api/v1/boards/qna/${qnaSeq}`, {method: Method.GET, query: {password: confirmPass, isLogin: isAdmin}});

      res.data.value.attach = [];

      this.qnaData = res.data.value as any;

      return res.data.value;

    },
    async replyQna(
        content         : string,
        isAttach        : 0 | 1,
        org_depth       : number,
        org_mainDomain  : string,
        org_password    : string,
        org_refNo       : number,
        org_refOrder    : number,
        org_subDomain   : string,
        title           : string,
        files           : File[],
    ){
      let boardCode = this.boardCode;
      let boardSettingSeq = this.boardSeq;



      const form = new FormData();

      const bodyParam = {
        boardCode       : boardCode,
        boardSettingSeq : boardSettingSeq,
        content         : content,
        isAttach        : isAttach,
        org_depth       : org_depth,
        org_mainDomain  : org_mainDomain,
        org_password    : org_password,
        org_refNo       : org_refNo,
        org_refOrder    : org_refOrder,
        org_subDomain   : org_subDomain,
        title           : title,
        userSeq         : useUserStore().userSeq
      }

      console.log(bodyParam);

      form.append('contents', JSON.stringify(bodyParam));

      if(files.length > 0){
        for(let i = 0; i < files.length; i++){
          form.append('files', files[i]);
        }
      }
  
      try{
        const res = await useCustomFetch('/api/v1/boards/qna/reply', {method:Method.POST, body: form});

        return res?.data.value;

      } catch(e){
        throw e;
      }   
    },
    async getBoardAttach(boardCode: string, attachSeq: number, attachName: string){
      const res = await useFetch(`/api/v1/boards/${boardCode}/attach`, {
        headers: {
          "Content-Type": '*',
          accept: '*'
        },
        method: Method.GET, query:{
        attachSeq: attachSeq
      }});

      console.log(res.data.value)

      if(res.data.value === null) return undefined;

      return {url: res.data.value, name: attachName, size: res.data.value.size};
      // return new File([res.data.value as any], attachName);
    },
    async saveContentImage(boardCode: string, files: File[]){
        const params = {
          boardCode: boardCode
        }  
        const form = new FormData();

        form.append('contents', JSON.stringify(params));

        for(let i = 0; i < files.length; i++){
          form.append('bodyImages', files[i]);
        }

        const res = await useFetch('/api/v1/boards/bodyImages', {method: Method.POST, body: form});

        return res?.data.value;
    },
    async loadContentImage(boardCode: string, bodyImageSeq: number){
      const res = await useFetch('/api/v1/boards/bodyImages', {method: Method.GET, query: {
        boardCode: boardCode,
        bodyImageSeq: bodyImageSeq
      }});

      return res.data.value;
    },
    async setNewsLetter(email: string, kinds: string){
      const res = await useFetch('/api/v1/newsletter', {method: Method.POST, query: {recipientKind: kinds, email: email}});

      return res;
    },
    async removeBoard(boardSeq: number){
      const boardCode = this.boardCode;

      const res = await useCustomFetch(`/api/v1/boards/${boardCode}/${boardSeq}`, {method: Method.DELETE});

      return res.data.value;
    },
    async removeQna(qnaSeq: number){
      const res = await useCustomFetch(`/api/v1/boards/qna/${qnaSeq}`, {method: Method.DELETE});

      return res.data.value;
    },
    async getMainPagePopup(category: string){
      const res = await useCustomFetch(`/api/v1/boards/mainPopups`, {method: Method.GET, query: {
        category: category
      }});

      return res?.data.value;
    },
    async getMainPagePopupSave(category: string, popupList: any){
      console.log(popupList);
      const bodyContent = new FormData();

      bodyContent.append('contents', JSON.stringify(popupList))

      const res = await useCustomFetch(`/api/v1/boards/mainPopups/order`, {method: Method.PUT, query:{category: category}, body: bodyContent});

      if(res?.data.value){
        return res.data.value
      } else {
        return res?.error.value
      }
    },
    async getNewsLetterSubScribe(
      pageNo: number,
      pageSize: number,
      recipientKind?: string,
      startDate?: string,
      endDate?: string,
      searchKind?: string,
      searchText?: string,
    ){

      this.boardCode = 'subscribe';

      if(!pageNo) pageNo = 1;
      if(!pageSize) pageSize = 10;


      const res = await useCustomFetch(`/api/v1/newsletter`, {method: Method.GET, query: {
        pageNo: pageNo,
        pageSize: pageSize,
        recipientKind: recipientKind,
        startDate: startDate,
        endDate: endDate,
        searchKind: searchKind,
        searchText: searchText,
      }});

      if(res?.data.value){
        this.boardList = res.data.value as any[];
      } else {
        return res?.error.value;
      }

    },
    async getNewsLetterExcel(){
      const res = await useCustomFetch(`/api/v1/newsletter/excel`, {method: Method.GET});

      if(res?.data){
        return res.data.value;
      } else {
        return res?.error.value;
      }
    },
    async modifyNewsLetter(
      dataSeq: number,
      recipientName: string,
      recipient: string
    ){
      try{
        const res = await useCustomFetch(`/api/v1/newsletter/${dataSeq}`, {method: Method.PUT, query:{
          recipientName: recipientName,
          recipient: recipient
        }});

        if(res?.data.value){
          return res.data.value
        } else {
          return res?.error.value
        }

      } catch(e){
        throw e;
      }
     
    },
    async removeNewsletter(dataSeq: number){
      try{
        const res = await useCustomFetch(`/api/v1/newsletter/${dataSeq}`, {method: Method.DELETE});

        if(res?.data.value){
          return res.data.value
        } else {
          return res?.error.value
        }

      } catch(e){
        throw e;
      }
     
    }
  }
})
